var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", [
    _c("iframe", {
      staticStyle: {
        border: "none",
        "max-width": "100%",
        "max-height": "100vh",
      },
      attrs: {
        width: "640px",
        height: "480px",
        src: "https://forms.office.com/Pages/ResponsePage.aspx?id=NqIq6LCuQ0SDxtQ9R4IdR1hYUwI1-85LlxHFIv7pRQVUN1NMSzBVSjA0NkRUUklLREhQWEI4SUM4Qi4u&embed=true",
        frameborder: "0",
        marginwidth: "0",
        marginheight: "0",
        allowfullscreen: "",
        webkitallowfullscreen: "",
        mozallowfullscreen: "",
        msallowfullscreen: "",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }